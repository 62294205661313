<template>
	<section class="content-main" style="position: relative">        
		<h1>{{form.name}}的店铺权限</h1>
		<el-tabs v-model="activeNameS" type="card" @tab-click="handleClick">
			<el-tab-pane label="店铺权限" name="1"></el-tab-pane>
			<el-tab-pane label="提供合作方案" name="2"></el-tab-pane>
			<el-tab-pane label="接受合作方案" name="3"></el-tab-pane>
		</el-tabs>
		<el-col :span="24" class="content-wrapper">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</el-col>
	</section>
</template>

<script>
    import {
            getStoreInfo
        } from '../../api/api';
    export default{
        data(){
            return {
                activeNameS: '1',
				aID: this.$route.query.id,
                form: {}
            };
        },
        methods: {
            //切换页面
            handleClick(tab, event) {
                switch(this.activeNameS){
					case '1':
                        this.$router.push({ name: '店铺权限', query: { type: '1',id: this.$route.query.id }})
						break;
                    case '2':
                        this.$router.push({ name: '提供合作方案', query: { type: '2',id: this.$route.query.id }})
                        break;
                    case '3':
                        this.$router.push({ name: '接受合作方案', query: { type: '3',id: this.$route.query.id }})
                        break;
				}
            },
            //获取信息
			getInfo() {
				let para = {
					store_id: this.aID
				};
				getStoreInfo(para).then((res) => {
					this.form = Object.assign({}, res.data);
				});
			},
        },
        mounted() {
            this.getInfo()
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        },
        updated: function () {
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        }
    }
</script>

<style lang="scss">
	.date-type{
		position: absolute;
		right: 0;
		top: 0;
		z-index: 9;
		.el-select {
			width: 100px;
		}
	}
</style>